<template>
  <section
    v-if="isShow"
    id="ecvNavbar__breadcrumb"
    class="ecvNavbar__breadcrumb"
    :class="breadcrumbClass"
  >
    <div class="d-flex container inner-container pa-0">
      <v-breadcrumbs :items="items">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item
            :to="getItemRoute(item)"
            exact
            :disabled="breadcrumbStatus == 2 || item.disabled"
          >
            <span class="v-breadcrumbs-item__text">{{ item.title }}</span>
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </div>
  </section>
</template>

<script>
export default {
  methods: {
    getItemRoute(item) {
      if (typeof item.to === "object") return item.to;
      return {
        name: item.name,
      };
    },
  },
  computed: {
    items() {
      return this.$store.getters["base/breadcrumb"];
    },
    isShow() {
      return !_isEmpty(this.items);
    },
    breadcrumbStatus() {
      return this.$store.getters["base/componentStatus"].breadcrumb;
    },
    logoChangeBase() {
      return this.$store.getters[`base/logoChangeBase`];
    },
    scroll() {
      return this.$store.getters["base/scroll"];
    },
    breadcrumbClass() {
      const { width } = this.$vuetify.breakpoint;
      if (width >= 900) {
        if (this.logoChangeBase == 0) {
          if (this.scroll == 0) return "ecvNavbar__breadcrumb--white";
          return "ecvNavbar__breadcrumb--color";
        }
        if (this.scroll <= this.logoChangeBase - 50) return "ecvNavbar__breadcrumb--white";
      }
      return "ecvNavbar__breadcrumb--color";
    },
  },
};
</script>
