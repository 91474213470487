<template>
  <div
    class="link-node"
    :class="{ 'link-node--is-link': isLink, 'link-node--not-link': !isLink }"
    :key="reDetectKey"
  >
    <v-tooltip :disabled="disabledTooltip" bottom>
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on">
          <component
            :is="component"
            :target="target"
            v-bind="properties"
            @click.native="stopPropagation"
            class="link-node__outter mb-0"
            :class="`text-overflow-ep-${lineClamp}`"
          >
            <span class="link-node__inner">{{ node[nameKey] }}</span>
          </component>
        </div>
      </template>
      <span>{{ node[nameKey] }}</span>
    </v-tooltip>
  </div>
</template>

<script lang="babel" type="text/babel">
export default {
  props: {
    node: {
      type: Object,
    },
    tooltip: {
      type: Boolean,
      default: false,
    },
    commonLink: {
      type: Boolean,
      default: false,
    },
    nameKey: {
      type: String,
      default: 'name'
    },
    hrefKey: {
      type: String,
      default: 'href'
    },
    lineClamp: {
      type: Number,
      default: 1
    },
    reDetectKey: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    hasOverflow: false,
  }),
  computed: {
    target() {
      if(!this.link) return null
      if(this.link.blank === true) return '_blank'
      return null
    },
    link() {
      if(this.commonLink) return this.node
      if(!this.nodeMeta) return null
      return this.nodeMeta.link
    },
    linkType() {
      if(!this.link) return 'none'
      return this.link.type || 'none'
    },
    properties() {
      if(this.commonLink) {
        return {
          href: this.link[this.hrefKey]
        }
      }
      if(this.linkType === 'none') return null
      if(this.linkType === 'external') {
        return {
          href: this.link[this.hrefKey]
        }
      }

      return {
        to: this.$helper.getMenuLink(this.nodeMeta, this)
      }
    },
    nodeMeta() {
      return this.node.meta
    },
    component() {
      if(this.commonLink) return 'a'
      if(this.linkType === 'external') return 'a'
      if(this.linkType != 'none') return 'router-link'
      return 'a' // 原本是寫 p or span 但這樣safari會有奇怪bug造成跑版
    },
    isLink() {
      if(this.commonLink) return true
      if(this.linkType === 'external') return true
      if(this.linkType != 'none') return true
      return false
    },
    disabledTooltip() {
      if(this.windowWidth < this.$vuetify.breakpoint.thresholds.md) return true
      if(!this.tooltip) return true
      if(this.hasOverflow) return false
      return true
    },
    windowWidth() {
      return this.$vuetify.breakpoint.width;
    },
  },
  watch: {
    async windowWidth() {
      await this.detect()
    },
    async reDetectKey() {
      await this.detect()
    },
  },
  async mounted() {
    await this.detect()
  },
  methods: {
    // 防止觸發父層 v-expansion-panels click事件
    stopPropagation(e) {
      if(this.linkType === 'none') return
      this.$store.commit("base/setNav", { key: "mobileMenu", status: false });
      e.stopPropagation()
    },
    async detect() {
      await this.$helper.delay(0.5)
      const outterHeight = $(this.$el).find('.link-node__outter').height()
      const innerHeight = $(this.$el).find('.link-node__inner').height()
      this.hasOverflow = innerHeight > outterHeight
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
