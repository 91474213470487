<template>
  <v-btn
    v-if="showBtn"
    height="40"
    :min-width="minWidth"
    rounded
    elevation="0"
    :outlined="outlined"
    :color="color"
    class="main-btn px-6"
    :target="target"
    v-bind="link"
    @click="emitClick"
  >
    <slot></slot>
  </v-btn>
</template>

<script>
import buttonMixins from "./button.js";
export default {
  mixins: [buttonMixins],
  props: {
    outlined: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "primary",
    },
    minWidth: {
      type: String,
      default: "100px",
    },
  },

};
</script>
