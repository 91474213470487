import downloadjs from "downloadjs";
import prettyBytes from "pretty-bytes";
export default {
  props: {
    fileItem: Object,
  },
  data: () => ({
    downloadLoading: false,
  }),
  methods: {
    async downloadConfirm() {
      if (!this.fileItem) {
        return;
      }
      await this.download();
    },
    isImage(fileType) {
      if (new RegExp(/image\/png/).test(fileType)) return true;
      if (new RegExp(/image\/jpg/).test(fileType)) return true;
      if (new RegExp(/image\/jpeg/).test(fileType)) return true;
      if (new RegExp(/image\/gif/).test(fileType)) return true;
      return false;
    },
    isPDF(fileType) {
      return fileType === "application/pdf";
    },
    async download() {
      if (this.downloadLoading == true) return;
      this.$store.dispatch("base/loading");
      this.downloadLoading = true;
      try {
        const uploadResult = await this.$api.file.public.download({
          path: this.fileItem.path,
        });
        console.log(uploadResult);
        const data = uploadResult.data;

        downloadjs(new Blob([data]), this.filename, data.type);
      } catch (error) {
        console.error(error);
        this.$snotify.error(this.$t('file.download.failure'))
      } finally {
        this.downloadLoading = false;
        this.$store.dispatch("base/loading", false);
      }
    },
  },
  computed: {
    fileBaseUrl() {
      return this.$store.getters["base/fileBaseUrl"];
    },
    size() {
      if (!this.fileItem) return 0;
      if (!this.fileItem.size) return 0;
      return prettyBytes(this.fileItem.size);
    },
    downloadFileRequest() {
      return this.$api.file.admin.download;
    },
    filename() {
      if (!this.fileItem) return null;
      return this.fileItem.original_filename;
    },
  },
};
