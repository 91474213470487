import { render, staticRenderFns } from "./ecvWaveR.vue?vue&type=template&id=f622e582&scoped=true&"
var script = {}
import style0 from "./ecvWaveR.vue?vue&type=style&index=0&id=f622e582&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f622e582",
  null
  
)

export default component.exports