<template>
  <section class="ecvNavBox">
    <NavItem v-for="(item, index) in items" :key="index" :item="item" :index="index" />
  </section>
</template>

<script>
export default {
  props: {
    items: Array
  },
  components: {
    NavItem: () => import("./navItem.vue"),
  },
  data: () => ({

  }),
};
</script>
