<template>
  <div class="d-flex justify-center align-center py-0 pt-1">
    <v-text-field
      v-model="data"
      @keydown.enter="search"
      @click:clear="clear"
      hide-details
      prepend-icon="$search"
      :dark="dark"
      :label="$t('action.search')"
      class="mt-0 ecvSearch"
      clearable
    ></v-text-field>
    <div>
      <v-btn
        @click="search"
        min-width="100px"
        rounded
        elevation="0"
        color="primary"
        class="mx-3 white--text ecvSearchBtn"
        >{{'action.search' | t}}</v-btn
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dark: {
      type: Boolean,
      default: true,
    },
    syncRouteQuery: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    data: null,
  }),
  computed: {
    computedData() {
      return _trim(this.data)
    },
  },
  created() {
   if(this.syncRouteQuery) this.data = this.$route.query.q;
  },
  methods: {
    search() {
      if(!this.computedData) return
      this.$router.push({ name: "search", query: { q: this.computedData } });
      this.$store.commit("base/setNav", {
        key: "ecvNavSearch",
        status: false,
      });
    },
    clear() {
      this.data = null
    },
  },
};
</script>