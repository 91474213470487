import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);
import siteConfig from 'config/site.js'
const basePath = siteConfig.featureMultipleSite === true
  ? '/:siteCode' : ''

const routes = [
  {
    path: basePath,
    component: () => import("@/AppSite.vue"),
    children: [
      {
        path: "",
        name: "home",
        component: () => import("@/views/home.vue"),
      },
      {
        path: "home-preview/:id",
        name: "home-preview",
        meta: {
          preview: true,
        },
        component: () => import("@/views/home.vue"),
      },
      // 動態頁面
      {
        path: "p/:code",
        name: "page",
        meta: {
          breadcrumb: true,
        },
        component: () => import("@/views/dynamicpage.vue"),
      },
      {
        path: "page-preview/:id",
        name: "page-preview",
        meta: {
          breadcrumb: true,
          preview: true,
        },
        component: () => import("@/views/dynamicpage.vue"),
      },
      {
        path: "blog",
        name: "blog-index",
        meta: {
          breadcrumb: true,
        },
        component: () => import("@/views/blog/index.vue"),
      },
      {
        path: "blog/:code",
        name: "blog-detail",
        meta: {
          breadcrumb: true,
        },
        component: () => import("@/views/blog/detail.vue"),
      },
      {
        path: "blog-preview/:id",
        name: "blog-preview",
        meta: {
          breadcrumb: true,
          preview: true,
        },
        component: () => import("@/views/blog/detail.vue"),
      },
      // 媒體中心
      {
        path: "newsroom",
        name: "media-index",
        meta: {
          breadcrumb: true,
        },
        component: () => import("@/views/blog/index.vue"),
      },
      {
        path: "newsroom/:code",
        name: "media-detail",
        meta: {
          breadcrumb: true,
        },
        component: () => import("@/views/blog/detail.vue"),
      },
      {
        path: "whitepaper",
        name: "white-book-index",
        meta: {
          breadcrumb: true,
        },
        component: () => import("@/views/whiteBook/index.vue"),
      },
      {
        path: "whitepaper/:code",
        name: "white-book-detail",
        meta: {
          breadcrumb: true,
        },
        component: () => import("@/views/whiteBook/detail.vue"),
      },
      {
        path: "whitepaper-preview/:id",
        name: "white-book-preview",
        meta: {
          breadcrumb: true,
          preview: true,
        },
        component: () => import("@/views/whiteBook/detail.vue"),
      },
      // 雲端講堂
      {
        path: "event",
        name: "cloud-index",
        meta: {
          breadcrumb: true,
        },
        component: () => import("@/views/cloud/index.vue"),
      },
      {
        path: "event/:code",
        name: "cloud-detail",
        meta: {
          breadcrumb: true,
        },
        component: () => import("@/views/cloud/detail.vue"),
      },
      {
        path: "event-preview/:id",
        name: "cloud-preview",
        meta: {
          breadcrumb: true,
          preview: true,
        },
        component: () => import("@/views/cloud/detail.vue"),
      },
      {
        path: "search",
        name: "search",
        meta: {
          breadcrumb: true,
        },
        component: () => import("@/views/search/search.vue"),
      },
      {
        path: "form-preview/:id",
        name: "form-preview",
        meta: {
          preview: true,
        },
        component: () => import("@/views/formPreview.vue"),
      },
      {
        path: "menu-preview/:id",
        name: "menu-preview",
        component: () => import("@/views/develop.vue"),
      },
      {
        path: "footer-preview/:id",
        name: "footer-preview",
        component: () => import("@/views/develop.vue"),
      },
      {
        path: "develop",
        name: "develop",
        meta: {
          breadcrumb: true,
        },
        component: () => import("@/views/develop.vue"),
      },
      {
        path: "404",
        name: "404",
        meta: {
          breadcrumb: true,
        },
        component: () => import("@/views/404Page.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
