<template>
  <router-link
    v-if="showBtn"
    :is="is"
    v-bind="link"
    :target="target"
    class="typeB-btn d-flex align-center pointer"
  >
    <span class="typeB-btn__text mr-3" :class="textClass">
      <slot></slot>
    </span>
    <v-btn :color="color" outlined fab small>
      <v-icon size="20" class="">ri-arrow-right-s-line</v-icon>
    </v-btn>
  </router-link>
</template>

<script>
import buttonMixins from "./button.js";
export default {
  mixins: [buttonMixins],
  props: {
    color: {
      type: String,
      default: "secondary",
    },
    linkOut: {
      type: Boolean,
      default: false,
    },
    overflowEp: {
      type: Number,
      default: 0
    },
  },
  computed: {
    textClass() {
      let classname = `${this.color}--text`
      if(this.overflowEp == 0) return classname
      return `${classname} text-overflow-ep-${this.overflowEp}`;
    },
    is() {
      if (_isEmpty(this.to)) return "span";
      if (this.linkOut) return "a";
      return "router-link";
    },
    link() {
      if (_isEmpty(this.to)) return null;
      if (this.linkOut) return { href: this.to };
      return { to: this.to };
    },
  },
  methods: {},
};
</script>