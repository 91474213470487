<template>
  <v-app :class="appClass" :lang="lang">
    <Nav :class="navClass" />

    <v-main v-if="applicationReady" id="ecvMain">
      <router-view></router-view>
    </v-main>

    <Footer />

    <!--loader-->
    <v-overlay :value="loading" :style="loadingStyle">
      <v-progress-circular indeterminate size="80"></v-progress-circular>
    </v-overlay>

    <AppExtra v-if="applicationLoaded"></AppExtra>
    <Cookie />
    <AnchorPoint />
  </v-app>
</template>

<script>
import AppExtra from "@/AppExtra.vue";
import Nav from "@/components/nav/nav.vue";
import Footer from "@/components/footer/footer.vue";
import Cookie from "@/components/cookie.vue";
import site from "@/config/site";

export default {
  data: () => ({
    navClass: "",
  }),
  computed: {
    siteData() {
      return this.$store.getters["base/siteData"];
    },
    lang() {
      if (!this.siteData) return site.siteCodeFallback;
      return this.siteData.language;
    },
    applicationReady() {
      return this.$store.getters["base/applicationReady"];
    },
    loadingStyle() {
      return {
        zIndex: 999999999,
      };
    },
    loading() {
      return this.$store.getters["base/loading"];
    },
    applicationLoaded() {
      return this.$store.getters["base/applicationLoaded"];
    },
    fullBg() {
      return [
        "404",
        "develop",
        "search",
        "footer-preview",
        "menu-preview",
      ].includes(this.$route.name);
    },
    appClassRouteName() {
      return `app-${this.$route.name}`;
    },
    appClass() {
      return {
        "full-background": this.fullBg,
        [this.appClassRouteName]: true,
      };
    },
    hideBodyScroll() {
      return this.$store.getters["base/hideBodyScroll"];
    },
    logoChangeBase() {
      return this.$store.getters[`base/logoChangeBase`];
    },
    scroll() {
      return this.$store.getters["base/scroll"];
    },
  },
  watch: {
    hideBodyScroll() {
      if (this.hideBodyScroll) {
        $("html").addClass("hide-scrollbar");
        return;
      }
      $("html").removeClass("hide-scrollbar");
    },
  },
  mounted() {
    this.init();
    this.$root.$on("setDynamicSubTitle", this.setDynamicSubTitle);
  },
  components: {
    AppExtra,
    Nav,
    Footer,
    Cookie,
    AnchorPoint: () => import("@/components/anchor/anchorPoint.vue"),
  },
  methods: {
    init() {
      this.setVuetify();
      this.setScroll();
      this.onScroll();
      this.$root.$on("onNavStatus", this.setNavStatus);
    },
    setVuetify() {
      this.$store.commit("base/setVuetify", this.$vuetify);
    },
    setNavStatus() {
      if (this.logoChangeBase == 0) {
        this.navClass = this.scroll > 0 ? "scroll-down" : "";
        return;
      }
      this.navClass =
        this.scroll > this.logoChangeBase - 50 ? "scroll-down" : "";
    },
    setScroll() {
      const scroll = $(window).scrollTop();
      this.$store.commit("base/setScroll", scroll);
    },
    onScroll() {
      const _this = this;
      $(window).scroll(async function () {
        _this.setScroll();
        await _this.$nextTick();
        _this.setNavStatus();
      });
    },
    async setDynamicSubTitle() {
      await this.$helper.delay(1);
      this._setDynamicSubTitleKernel();
      // safari有時會沒吃到, 所以隔一秒再執行一次
      await this.$helper.delay(1);
      this._setDynamicSubTitleKernel();
    },
    _setDynamicSubTitleKernel() {
      $(this.$el)
        .find(".dynamic-sub-title")
        .each(function () {
          if ($(this).hasClass("dynamic-sub-title--active")) return;

          $(this).addClass("dynamic-sub-title--active");
          $(this).wrapInner('<span class="dynamic-sub-title__text"></span>');
          $(this).append('<span class="dynamic-sub-title__line"></span>');
        });
    },
  },
};
</script>
<style lang="sass" type="text/sass" src="@/assets/sass/index.sass"></style>
