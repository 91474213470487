// 這邊放的是不會改的各種網站常用設定
module.exports = {
  siteName: '數位因子dgFactor',

  /**
   * 是否使用資料審核功能
   */
  featureDataReview: false,

  /**
   * 是否使用多個分站
   */
  featureMultipleSite: false,

  /**
   * 管理員的信件語系
   */
  featureAdminMailLanguage: 'zh-TW',

  /**
   * 當使用單一分站模式
   * 使用的預設site code
   */
  defaultSiteCode: 'tw',

  /**
   * 預設分站
   * 在多站模式下
   * 若找不到分站資料要導向的分站
   * 與defaultSiteCode設定相同即可
   */
  siteCodeFallback: 'tw',
}
