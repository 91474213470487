import moment from 'moment'
import 'moment/locale/zh-tw'
moment.locale('zh-tw')
import _cloneDeep  from 'lodash/cloneDeep'
import _isEqual  from 'lodash/isEqual'
import _isEmpty  from 'lodash/isEmpty'
import _trim  from 'lodash/trim'
import _findIndex  from 'lodash/findIndex'
import _uniqBy  from 'lodash/uniqBy'
import _get  from 'lodash/get'
import _merge  from 'lodash/merge'
import _chunk from 'lodash/chunk'
import _last from 'lodash/last'
window._cloneDeep = _cloneDeep
window._isEqual = _isEqual
window._isEmpty = _isEmpty
window._trim = _trim
window._findIndex = _findIndex
window._uniqBy = _uniqBy
window._get = _get
window._merge = _merge
window._chunk = _chunk
window._last = _last
class helper {
  constructor() {
    this.moment = moment
    this.init()
  }

  delay(second) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve()
      }, second*1000)
    })
  }

  async init() {
  }

  getRecursive(data, key, withoutWarning = false) {
    let result = _cloneDeep(data)
    try {
      let properties = key.split('.')
      if(properties.length == 1) return data[key]
      for(const property of properties) {
        if(result[property] === undefined || result[property] === null) return undefined
        result = result[property]
      }
    } catch (error) {
      if(process.env.NODE_ENV != 'testing') {
        if(!withoutWarning) console.warn(error)
      }
      return undefined
    }
    return result
  }

  setRecursive(data, key, value) {
    let target = data
    try {
      let properties = key.split('.')
      let last = properties.pop()
      for(const property of properties) {
        if(target[property] === undefined) {
          target[property] = {}
        }
        target = target[property]
      }

      if(target === null || target === undefined) {
        target = {}
      }
      target[last] = value
    } catch(error) {
      console.warn(error)
      return data
    }
    return data
  }

  getCountryCodeFromLanguageCode(code, lowercase = false) {
    if(typeof code != 'string') return
    const partials = code.split('-')
    let result = partials.length == 1 ? partials[0] : partials[1]
    if(lowercase) return result.toLowerCase()
    return result
  }

  // logout confirm
  logout(vm) {
    vm.$apopup.base({
      title: vm.i18n('action.logout'),
      content: vm.i18n('confirm.logout'),
      closeLabel: vm.i18n('action.cancel'),
      applyCallback: () => {
        vm.$store.dispatch('token/clean')
        vm.$snotify.success(
          null,
          vm.i18n('logout.success')
        )
      },
    })
  }

  async getCustomVueComponent(component) {
    if(!component) return
    if(typeof component != 'function') return component
    const { default: result } = await component()
    return result
  }

  render(data, variable = {}) {
    return mustacheRender.render(data, variable)
  }

  getSuffixListByDevice() {
    const windowWidth = $(window).width()
    if(windowWidth < 660) return ['middle','small', 'tiny']
    if(windowWidth < 960) return ['middle', 'small', 'tiny']
    if(windowWidth < 1264) return ['large', 'middle', 'small', 'tiny']
    return ['xlarge', 'large', 'middle', 'small', 'tiny']
  }

  // 移除photo list的null項目
  getComputedPhotoList(photos) {
    if(!Array.isArray(photos)) return null
    const validPhotos = photos.filter(photo => !!photo)
    if(validPhotos.length == 0) return null
    return validPhotos
  }

  getPhotoListUrl(photos, suffixList = null) {
    if(!Array.isArray(photos)) return null
    photos = photos.filter(photo => !!photo)
    return this.getPhotoUrl(photos[0], suffixList)
  }

  getPhotoUrl(photo, suffixList = null) {
    if(!photo) return null
    if(photo.url) return photo.url
    if(!photo.size_list) return null
    if(!suffixList) suffixList = this.getSuffixListByDevice()
    const originUrl = photo.size_list.origin.url
    for(const suffix of suffixList) {
      if(photo.size_list[suffix] && photo.size_list[suffix].url) {
        return photo.size_list[suffix].url
      }
    }
    return originUrl
  }

  getPhotoUrlArray(photo) {
    if(!photo) return null
    if(photo.url) return [photo.url]
    if(!photo.size_list) return [photo.size_list]
    let result = []
    for(const suffix in photo.size_list) {
      const photoSizeObject = photo.size_list[suffix]
      if(!photoSizeObject.url) continue
      result.push(photoSizeObject.url)
    }
    return result
  }

  getPhotoName(photo) {
    if(!photo) return ''
    return photo.name
  }

  getSize(byte) {
    if(byte < 1024*1024) return `${(byte/1024).toFixed(2)}KB`
    if(byte < 1024*1024*1024) return `${(byte/1024/1024).toFixed(2)}MB`
    return `${(byte/1000/1000/1024).toFixed(2)}GB`
  }

  isImage(file) {
    if(file instanceof File === false) return false
    if(new RegExp(/image\/png/).test(file.type)) return true
    if(new RegExp(/image\/jpg/).test(file.type)) return true
    if(new RegExp(/image\/jpeg/).test(file.type)) return true
    if(new RegExp(/image\/gif/).test(file.type)) return true
    return false
  }

  getCountdown(time) {
    if(!time) return null
    const now = this.moment()
    const diffTime = this.moment(time).diff(now)
    const duration = this.moment.duration(diffTime, 'milliseconds')
    let hours = duration.hours() > 10 ? duration.hours() : `0${duration.hours()}`
    let minutes = duration.minutes() > 10 ? duration.minutes() : `0${duration.minutes()}`
    let seconds = duration.seconds() > 10 ? duration.seconds() : `0${duration.seconds()}`
    let result = seconds
    if(duration.minutes()) result = `${minutes}:${result}`
    if(duration.hours()) result = `${hours}:${result}`
    return result
  }

  afterLoginSuccess(vm, role) {
    this._redirectAfterLoginSuccess(vm)
    if(role == 'user') {
      this._emitLoginSuccessEventAfterLoginSuccess(vm)
    }
  }

  _redirectAfterLoginSuccess(vm) {
    const path = vm.$route.query.path
    if(!path) return
    vm.$router.replace({ path })
  }

  async _emitLoginSuccessEventAfterLoginSuccess(vm) {
    try {
      await vm.$api.userAccount.admin.emitLoginSuccessEvent()
    } catch (error) {
      console.error(error)
    }
  }

  getBreakpointByWidth(width) {
    if(width < 600) return 'xs'
    if(width < 960) return 'sm'
    if(width < 1264) return 'md'
    if(width < 1904) return 'lg'
    return 'xl'
  }

  getDeviceByWidth(width) {
    const breakpoint = this.getBreakpointByWidth(width)
    if(breakpoint == 'xl' || breakpoint == 'lg') return 'desktop'
    if(breakpoint == 'md') return 'tablet'
    if(breakpoint == 'sm' || breakpoint == 'xs') return 'mobile'
    return null
  }

  getYoutubeUid(url) {
    if(typeof url != 'string') return null
    const partial = url.split('v=')
    if(typeof partial[1] != 'string') return null
    const uid = partial[1].split('&')[0 ]
    return uid
  }

  getSize(byte) {
    if(byte < 1024*1024) return `${(byte/1024).toFixed(2)}KB`
    if(byte < 1024*1024*1024) return `${(byte/1024/1024).toFixed(2)}MB`
    return `${(byte/1000/1000/1024).toFixed(2)}GB`
  }

  formRules(vm) {
    return {
      textLengthRule: (value, length) => {
        const error = vm.i18n('base.validation.text_too_less', { min: length })
        if(typeof value != 'string') return error
        if(!vm.$validator.isLength(value, { min: length })) return error
        return true
      },
      requiredRule:(value) => {
        return !_isEmpty(_trim(value)) || vm.i18n('base.validation.required')
      },
      validEmail: value => {
        const error = i18n('base.validation.email.incorrect')
        if(this.validate().validEmail(vm, value) === false) return error
        return true
      },
      hasLettersAndNumber: value => {
        const error = i18n('base.validation.should_contain_letters_and_numeric')
        if(this.validate().hasLettersAndNumber(value) === false) return error
        return true
      },
    }
  }

  validate() {
    return {
      validZip: (vm, value) => {
        if(typeof value != 'string') return false
        if(vm.$validator.isLength(value, { min: 3 }) === false) return false
        if(vm.$validator.isNumeric(value) === false) return false
        return true
      },
      validEmail: (vm, value) => {
        if(typeof value != 'string') return false
        if(vm.$validator.isEmail(value) === false) return false
        return true
      },
      hasLettersAndNumber: value => {
        if(typeof value != 'string') return false
        const LETTERS_PATTEN = /[a-zA-Z]/
        const NUMERIC_PATTEN = /[0-9]/
        const hasLetters = new RegExp(LETTERS_PATTEN, 'g').test(value) == true
        const hasNumeric = new RegExp(NUMERIC_PATTEN, 'g').test(value) == true
        if(!hasLetters) return false
        if(!hasNumeric) return false
        return true
      },
      minText(vm, value, length) {
        if(typeof value != 'string') return false
        return vm.$validator.isLength(value, { min: length })
      }
    }
  }

  getMenuLink(nodeMeta, vm) {
    if(!nodeMeta) return null
    if(!nodeMeta.link) return null
    const link = nodeMeta.link
    if(link.type === 'none') return null
    if(link.href) return href
    if(link.type === 'blog-index') return { name: 'blog-index' }
    if(link.type === 'media-index') return { name: 'media-index' }
    if(link.type === 'paper-index') return { name: 'white-book-index' }
    if(link.type === 'cloud-index') return { name: 'cloud-index' }
    if(link.type === 'page') {
      return {
        name: 'page',
        params: {
          code: link.code,
        },
      }
    }

    if(link.type === 'blog') {
      return {
        name: 'blog-detail', params: { code: link.code },
      }
    }

    if(link.type === 'media') {
      return {
        name: 'media-detail', params: { code: link.code },
      }
    }

    if(link.type === 'cloud') {
      return {
        name: 'cloud-detail', params: { code: link.code },
      }
    }

    if(link.type === 'paper') {
      return {
        name: 'white-book-detail', params: { code: link.code },
      }
    }

    console.warn('未定義link.type', link.type)
    return {
      name: link.type,
    }
  }

  handleAnchorName(name) {
    if(!name) return ''
    return name.trim().replace(/ /g, '-')
  }

  modifyUrl(url, vm) {
    return url
  }

  dateFormat({lang, time = true, year = true}) {
    let format = ''
    if(['zh-TW', 'zh-CN'].includes(lang)) {
      format = year ? "YYYY/MM/DD (dd)" : "MM/DD (dd)"
    } else {
      format = year ? "MMMM DD, YYYY" : "MMMM DD"
    }
    return time ? `${format} HH:ss` : format
  }

  formatDate(vm, data) {
    if (!data) return "";
    const lang = vm.$store.getters['base/siteLanguage']
    if(!lang) return ''
    const format = this.dateFormat({lang, time: false})
    return this.moment(data).locale(lang).format(format)
  }

  formatDateRange(vm, data, breakLine = false) {
    if (!data) return "";
    const lang = vm.$store.getters['base/siteLanguage']
    if(!lang) return ''
    const startYear = this.moment(data.start_at).format("YYYY");
    const endYear = this.moment(data.end_at).format("YYYY");
    const startDate = this.moment(data.start_at).format("MMDD");
    const endDate = this.moment(data.end_at).format("MMDD");
    let sameYear = startYear == endYear
    let sameDate = startDate == endDate
    let stratFormat = ''
    let endFormat = ''

    if(!sameYear) {
      stratFormat = endFormat = this.dateFormat({lang})
    }
    else if(!sameDate) {
      stratFormat = endFormat = this.dateFormat({lang})
    }
    else {
      stratFormat = this.dateFormat({lang})
      endFormat = "HH:ss"
    }

    let start_at = this.moment(data.start_at).locale(lang).format(stratFormat)
    let end_at = this.moment(data.end_at).locale(lang).format(endFormat)

    if(sameYear && sameDate) {
      return `${start_at} - ${end_at}`
    }
    return breakLine ? `${start_at} - <br> ${end_at}` : `${start_at} - ${end_at}`
  }
}


export default new helper()
